import { Link } from 'gatsby';
import React from 'react';

const Profile = () => {
  return (
    <>
      <h1>Profil</h1>
      <Link to="/">Powrót</Link>
    </>
  );
};

export default Profile;
